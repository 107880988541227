import { useMemo } from 'react';
import { getSessionStatistics } from '../requests.mjs';
import useResult from '../response';

export default function useGetSessionStatistics(auth, siteId = null, trainerId = null, params = null) {
  const request = useMemo(
    function () {
      return getSessionStatistics(auth, siteId, trainerId, params);
    },
    [siteId, trainerId, params]
  );
  return useResult(request);
}
