import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function getDuration(duration) {
  const hours = Math.floor(duration / 1000 / 3600);
  const minutes = Math.floor((duration - hours * 3600 * 1000) / 1000 / 60);
  const seconds = Math.ceil(duration / 1000 % 60);
  if (hours === 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${hours}h ${minutes}m ${seconds}s`;
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <TableRow sx={{verticalAlign: 'text-top'}}>
      <TableCell component="th" scope="row">
        {row.site.name}
      </TableCell>
      <TableCell>
        {row.sessions}
      </TableCell>
      <TableCell>
        {getDuration(row.duration)}
      </TableCell>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}}>Trainer</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Sessions Executed</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Total Session Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.trainers.map((trainer) => (
                <TableRow key={trainer.trainer.trainerId}>
                  <TableCell component="th" scope="row">
                    {trainer.trainer.firstName} {trainer.trainer.lastName}
                  </TableCell>
                  <TableCell>
                    {trainer.sessions}
                  </TableCell>
                  <TableCell>
                    {getDuration(trainer.duration)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

export default function sessionStatisticsTable({statistics}) {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Site</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Sessions Executed</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Total Session Duration</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statistics.map((row) => (
            <Row key={row.site.siteId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
