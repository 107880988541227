import { useMemo } from "react";
import { getTags } from "../requests.mjs";
import useResult from "../response";

export default function useGetTags(auth, params=null) {
  const request = useMemo(function() {
    return getTags(auth, params);
  },
  [params]);
  return useResult(request);
};
