import { useState, useEffect } from 'react';
import moment from 'moment';
import request, { getSessionStatistics } from '../apis/thrivePerformance/v1/requests.mjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import SessionStatisticsFilter from '../components/session/statistics/filter';
import SessionStatisticsTable from '../components/session/statistics/table';

export default function Home({
  isLoggedIn = false,
  auth = null,
  setSnackbar = () => {},
}) {
  const now = moment().hour(23).minute(59).second(59).millisecond(999);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(now);
  const [params, setParams] = useState({
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  });
  const [statistics, setStatistics] = useState(null);


  function handleStatisticsFilter() {
    setParams({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  }

  function refreshStatistics() {
    setLoading(true);
    request(getSessionStatistics(auth, null, null, params))
    .then((statistics) => {
      if (statistics) {
        setStatistics(statistics);
      }
    })
    .catch((err) => {
      setStatistics(null);
      if (err.statusCode !== 404) {
        console.log(err);
        setSnackbar('error', 'An error occurred while attempting to retrieve the session statistics.');
      }
    });
    setLoading(false);
  }

  useEffect(() => {
    if (isLoggedIn && !loading) {
      refreshStatistics();
    }
  }, [isLoggedIn, params]);

  return (
    <Box>
      <Typography variant="h3" component="h3" marginBottom="1rem">
        Welcome to Thrive Performance Web
      </Typography>
      {!isLoggedIn && (
        <Typography variant="body" component="p">
          <RouterLink to="/login">Click here to Login</RouterLink>
        </Typography>
      )}
      {isLoggedIn && (
        <>
          <Typography variant="h4" component="h4" marginBottom="1rem">
            Session Statistics
          </Typography>
          <SessionStatisticsFilter
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleFilter={handleStatisticsFilter}
          />
          {loading && (
            <Typography variant="body" component="p">
              Loading...
            </Typography>
          )}
          {!loading && statistics && (
            <SessionStatisticsTable
              statistics={statistics}
            />
          )}
          {!loading && !statistics && (
            <Typography variant="body" component="p" sx={{mt: 2}}>
              No session statistics were found for the provided date range.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
