import { useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import moment from 'moment';
import useGetSite from '../../apis/thrivePerformance/v1/sites/getSite';
import useGetClients from '../../apis/thrivePerformance/v1/clients/getClients';
import useGetTrainers from '../../apis/thrivePerformance/v1/user/getTrainers';
import useGetSessionStatistics from '../../apis/thrivePerformance/v1/sessions/getStatistics';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClientCard from '../../components/client/card';
import ClientCreate from '../../components/client/create';
import ClientEdit from '../../components/client/edit';
import TrainerAssign from '../../components/client/reassign';
import UserCard from '../../components/user/card';
import UserCreate from '../../components/user/create';
import UserEdit from '../../components/user/edit';
import request, {
  createClient,
  updateClient,
  reassignClient,
  updateUser,
  createUser,
  disableUser,
  enableUser,
  resetPassword,
  deleteUser,
} from '../../apis/thrivePerformance/v1/requests.mjs';
import SessionStatisticsFilter from '../../components/session/statistics/filter';
import SessionStatisticsTable from '../../components/session/statistics/table';

export default function Dashboard() {
  const { siteId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] =
    useOutletContext();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [site, siteError, refreshSite] = useGetSite(auth, siteId);
  const [clientParams, setClientParams] = useState({});
  const [clients, clientsError, refreshClients] = useGetClients(auth, clientParams, siteId);
  const [clientsPage, setClientsPage] = useState(1);
  const [clientsSearchTerm, setClientsSearchTerm] = useState('');
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [editClientDialogOpen, setEditClientDialogOpen] = useState(false);
  const [trainerAssignDialogOpen, setTrainerAssignDialogOpen] = useState(false);
  const [client, setClient] = useState({});
  const [trainerParams, setTrainerParams] = useState({siteId: siteId});
  const [trainers, trainersError, refreshTrainers] = useGetTrainers(auth, trainerParams);
  const [createTrainerDialogOpen, setCreateTrainerDialogOpen] = useState(false);
  const [editTrainerDialogOpen, setEditTrainerDialogOpen] = useState(false);
  const [trainer, setTrainer] = useState({});
  const now = moment().hour(23).minute(59).second(59).millisecond(999);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(now);
  const [statisticsParams, setStatisticsParams] = useState({
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  });
  const [statistics, statisticsError, refreshStatistics] = useGetSessionStatistics(auth, siteId, null, statisticsParams);
  
  function handleClientsFilter() {
    setLoading(true);
    try {
      setClientParams({
        searchTerm: clientsSearchTerm,
      });
      refreshClients();
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the clients.');
    }
    setLoading(false);
  }

  if (siteError) {
    setSnackbar('error', siteError.error);
  }

  async function handleClientCreate(client) {
    setProcessing(true);
    try {
      await request(createClient(auth, client));
      setCreateClientDialogOpen(false);
      refreshClients();
      setSnackbar('success', 'The client was created successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the client.');
    }
    setProcessing(false);
  }

  async function handleClientEdit(client) {
    setProcessing(true);
    try {
      await request(updateClient(auth, client, siteId));
      setEditClientDialogOpen(false);
      refreshClients();
      setSnackbar('success', 'The client was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the client.');
    }
    setProcessing(false);
  }

  async function handleClientReassign(trainerId) {
    setProcessing(true);
    try {
      await request(reassignClient(auth, client.clientId, trainerId, siteId));
      setTrainerAssignDialogOpen(false);
      refreshClients();
      setSnackbar('success', 'The client was reassigned successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to reassign the client.');
    }
    setProcessing(false);
  }

  function handleTrainerCreate() {
    setTrainer({
      email: '',
      firstName: '',
      lastName: '',
      locale: 'en-US',
      timeZone: 'America/Toronto',
      siteId: siteId,
      groups: [{
        name: "User",
        description: "",
        precedence: 1,
      }],
      tags: [],
    });
    setCreateTrainerDialogOpen(true);
  }

  function handleTrainerEdit(trainer) {
    setTrainer(trainer);
    setEditTrainerDialogOpen(true);
  }

  async function handleCreateTrainer(trainer) {
    setProcessing(true);
    try {
      await request(createUser(auth, trainer));
      setCreateTrainerDialogOpen(false);
      refreshTrainers();
      setSnackbar(
        'success',
        'The trainer was created successfully. They have been sent an invitation email.'
      );
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the trainer.');
    }
    setProcessing(false);
  }

  async function handleUpdateTrainer(trainer) {
    setProcessing(true);
    try {
      await request(updateUser(auth, trainer));
      setEditTrainerDialogOpen(false);
      refreshTrainers();
      setSnackbar('success', 'The trainer information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the trainer information.');
    }
    setProcessing(false);
  }

  async function handleDisableTrainer(id) {
    setProcessing(true);
    try {
      await request(disableUser(auth, id));
      refreshTrainers();
      setSnackbar('success', 'The trainer was disabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to disable the trainer.');
    }
    setProcessing(false);
  }

  async function handleEnableTrainer(id) {
    setProcessing(true);
    try {
      await request(enableUser(auth, id));
      refreshTrainers();
      setSnackbar('success', 'The trainer was enabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to enable the trainer.');
    }
    setProcessing(false);
  }

  async function handleResetTrainerPassword(id) {
    setProcessing(true);
    try {
      await request(resetPassword(auth, id));
      refreshTrainers();
      setSnackbar(
        'success',
        "The trainer's password was reset successfully. They have been sent an email."
      );
    } catch (err) {
      console.log(err);
      setSnackbar('error', "An error occurred while attempting to reset the trainer's password.");
    }
    setProcessing(false);
  }

  async function handleDeleteTrainer(id) {
    setProcessing(true);
    try {
      await request(deleteUser(auth, id));
      refreshTrainers();
      setSnackbar('success', 'The trainer was deleted successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to delete the trainer.');
    }
    setProcessing(false);
  }

  function handleCreateClientDialogOpen() {
    setClient({
      firstName: '',
      lastName: '',
      email: '',
      siteId: siteId,
      trainerId: '',
    });
    setCreateClientDialogOpen(true);
  }

  function handleEditClientDialogOpen(client) {
    setClient(client);
    setEditClientDialogOpen(true);
  }

  function handleReassignDialogOpen(client) {
    setClient(client);
    setTrainerAssignDialogOpen(true);
  }

  function handleDialogClose() {
    setCreateClientDialogOpen(false);
    setEditClientDialogOpen(false);
    setTrainerAssignDialogOpen(false);
    setEditTrainerDialogOpen(false);
    setCreateTrainerDialogOpen(false);
  }

  function handleStatisticsFilter() {
    setStatisticsParams({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  }

  useEffect(() => {
    if (clientsError) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', clientsError.error);
    }
    if (trainersError) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', trainersError.error);
    }
    if (statisticsError) {
      if (loading) {
        setLoading(false);
      }
      if (statisticsError.statusCode !== 404) {
        setSnackbar('error', statisticsError.error);
      }
    }
  }, [clientsError, trainersError, statisticsError]);

  const handleClientPageChange = (event, value) => {
    setClientParams({
      ...clientParams,
      ...{ startFrom: (value - 1) * clients.limit },
    });
    setClientsPage(value);
  };

  const totalClientPages =
    clients && clients.totalClients
      ? Math.floor((clients.totalClients - 1) / clients.limit) + 1
      : 1;

  return (
    <>
      <Typography variant="h3" component="h3">
        {`Site: ${(site?.name || '')}`}
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h4" component="h4">
          Clients
        </Typography>
        <IconButton size="large" color="primary" onClick={handleCreateClientDialogOpen}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          id="filled-search"
          label="Search Clients"
          type="search"
          variant="filled"
          value={clientsSearchTerm}
          onChange={(event) => setClientsSearchTerm(event.target.value)}
        />
        <IconButton size="large" color="primary" onClick={handleClientsFilter}>
          <SearchIcon />
        </IconButton>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {clients &&
          clients.clients &&
          clients.clients.map((client) => {
            return (
              <Grid xs={12} sm={6} lg={4} key={client.clientId}>
                <ClientCard
                  client={client}
                  handleEdit={handleEditClientDialogOpen}
                  handleReassign={handleReassignDialogOpen}
                  auth={auth}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                  sx={{ pt: 2 }}
                  siteId={siteId}
                />
              </Grid>
            );
          })}
        <br />
        {!clients && loading && (
          <Grid xs>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
      {totalClientPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid xs>
            <Pagination
              count={totalClientPages}
              page={clientsPage}
              onChange={handleClientPageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h4" component="h4">
          Trainers
        </Typography>
        <IconButton size="large" color="primary" onClick={handleTrainerCreate}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
        {trainers &&
          trainers.map((trainer) => {
            return (
              <Grid xs={12} sm={6} lg={4} key={trainer.userId}>
                <UserCard
                  user={trainer}
                  processing={processing}
                  handleEdit={handleTrainerEdit}
                  handleResetPassword={handleResetTrainerPassword}
                  handleDisable={handleDisableTrainer}
                  handleDelete={handleDeleteTrainer}
                  handleEnable={handleEnableTrainer}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                  sx={{ pt: 2 }}
                />
              </Grid>
            );
          })}
        <br />
        {!clients && loading && (
          <Grid xs>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
      <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
        <Typography variant="h4" component="h4">
          Session Statistics
        </Typography>
        <SessionStatisticsFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleFilter={handleStatisticsFilter}
        />
        {!loading && statistics && (
          <SessionStatisticsTable
            statistics={statistics}
          />
        )}
        {!loading && !statistics && (
          <Typography variant="body" component="p" sx={{mt: 2}}>
            No session statistics were found for the provided date range.
          </Typography>
        )}
      </Stack>
      <Dialog open={createClientDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>New Client</DialogTitle>
        <DialogContent>
          <ClientCreate
            client={client}
            trainers={trainers}
            processing={processing}
            handleCreate={handleClientCreate}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={editClientDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Client Information</DialogTitle>
        <DialogContent>
          <ClientEdit
            client={client}
            processing={processing}
            handleUpdate={handleClientEdit}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={trainerAssignDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Trainer Assignment</DialogTitle>
        <DialogContent>
          <TrainerAssign
            client={client}
            trainers={trainers}
            processing={processing}
            handleReassign={handleClientReassign}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={editTrainerDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Trainer Information</DialogTitle>
        <DialogContent>
          <UserEdit
            user={trainer}
            processing={processing}
            auth={auth}
            handleUpdate={handleUpdateTrainer}
            handleCancel={handleDialogClose}
            setSnackbar={setSnackbar}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={createTrainerDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create New Trainer</DialogTitle>
        <DialogContent>
          <UserCreate
            user={trainer}
            processing={processing}
            auth={auth}
            handleCreate={handleCreateTrainer}
            handleCancel={handleDialogClose}
            setSnackbar={setSnackbar}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
