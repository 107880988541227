import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_THRIVEPERFORMANCE_V1_API_URL;

function createUrl(base, path) {
  return `${base}${path}`;
}

function createAuthHeader(token) {
  return {
    authorization: `Bearer ${token}`,
  };
}

//request structure for each of the endpoints in this API
export function createAvatar(auth, data) {
  return [
    createUrl(BASE_URL, '/user/avatar'),
    {
      method: 'post',
    },
    data,
    auth,
  ];
}

export function createSite(auth, site) {
  return [
    createUrl(BASE_URL, '/site'),
    {
      method: 'post',
    },
    site,
    auth,
  ];
}

export function getSites(auth, params = null) {
  return [
    createUrl(BASE_URL, '/sites'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getSite(auth, siteId) {
  return [
    createUrl(BASE_URL, `/site/${siteId}`),
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function updateSite(auth, site) {
  return [
    createUrl(BASE_URL, `/site/${site.siteId}`),
    {
      method: 'patch',
    },
    site,
    auth,
  ];
}

export function disableSite(auth, siteId) {
  return [
    createUrl(BASE_URL, `/site/${siteId}/disable`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function enableSite(auth, siteId) {
  return [
    createUrl(BASE_URL, `/site/${siteId}/enable`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function createPurchasableItem(auth, item) {
  return [
    createUrl(BASE_URL, '/purchasableItem'),
    {
      method: 'post',
    },
    item,
    auth,
  ];
}

export function getPurchasableItems(auth, params = null) {
  return [
    createUrl(BASE_URL, '/purchasableItems'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getPurchasableItem(auth, itemId) {
  return [
    createUrl(BASE_URL, `/purchasableItem/${itemId}`),
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function updatePurchasableItem(auth, item) {
  return [
    createUrl(BASE_URL, `/purchasableItem/${item.itemId}`),
    {
      method: 'patch',
    },
    item,
    auth,
  ];
}

export function activatePurchasableItem(auth, itemId) {
  return [
    createUrl(BASE_URL, `/purchasableItem/${itemId}/activate`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function deactivatePurchasableItem(auth, itemId) {
  return [
    createUrl(BASE_URL, `/purchasableItem/${itemId}/deactivate`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function createClient(auth, client) {
  return [
    createUrl(BASE_URL, '/client'),
    {
      method: 'post',
    },
    client,
    auth,
  ];
}

export function getClients(auth, params = null, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/clients`);
  } else {
    url = createUrl(BASE_URL, '/clients');
  }
  return [
    url,
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getClient(auth, clientId, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}`);
  }
  return [
    url,
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function updateClient(auth, client, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${client.clientId}`);
  } else {
    url = createUrl(BASE_URL, `/client/${client.clientId}`);
  }
  return [
    url,
    {
      method: 'patch',
    },
    client,
    auth,
  ];
}

export function reassignClient(auth, clientId, trainerId, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/reassign`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/reassign`);
  }
  return [
    url,
    {
      method: 'patch',
    },
    {trainerId: trainerId},
    auth,
  ];
}

export function getWallets(auth, clientId, params = null, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/wallets`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/wallets`);
  }
  return [
    url,
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getWallet(auth, clientId, walletId, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/wallet/${walletId}`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/wallet/${walletId}`);
  }
  return [
    url,
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function addItemToWallet(auth, clientId, walletId, item, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/wallet/${walletId}/item`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/wallet/${walletId}/item`);
  }
  return [
    url,
    {
      method: 'post',
    },
    item,
    auth,
  ];
}

export function removeItemFromWallet(auth, clientId, walletId, itemId, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/wallet/${walletId}/item/${itemId}`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/wallet/${walletId}/item/${itemId}`);
  }
  return [
    url,
    {
      method: 'delete',
    },
    null,
    auth,
  ];
}

export function getSessions(auth, clientId, params = null, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/sessions`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/sessions`);
  }
  return [
    url,
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getSession(auth, clientId, sessionId, siteId = null) {
  let url = null;
  const primaryGroup = auth.getUserPrimaryGroup(auth);
  if (primaryGroup === 'Admin') {
    url = createUrl(BASE_URL, `/site/${siteId}/client/${clientId}/session/${sessionId}`);
  } else {
    url = createUrl(BASE_URL, `/client/${clientId}/session/${sessionId}`);
  }
  return [
    url,
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function createUser(auth, user) {
  return [
    createUrl(BASE_URL, '/user'),
    {
      method: 'post',
    },
    user,
    auth,
  ];
}

export function getUsers(auth, params = null) {
  return [
    createUrl(BASE_URL, '/users'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}`),
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function updateUser(auth, user) {
  return [
    createUrl(BASE_URL, `/user/${user.userId}`),
    {
      method: 'patch',
    },
    user,
    auth,
  ];
}

export function disableUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/disable`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function enableUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/enable`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function resetPassword(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/resetPassword`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function deleteUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}`),
    {
      method: 'delete',
    },
    null,
    auth,
  ];
}

export function getTrainers(auth, params = null) {
  return [
    createUrl(BASE_URL, '/users/trainers'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getTags(auth, params=null) {
  return [
    createUrl(BASE_URL, '/tags'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth
  ];
}

export function createTag(auth, tag) {
  return [
    createUrl(BASE_URL, '/tag'),
    {
      method: 'post',
    },
    tag,
    auth
  ];
}

export function getSessionStatistics(auth, siteId=null, trainerId=null, params=null) {
  let url = null;
  if (siteId !== null) {
    if (trainerId !== null) {
      url = createUrl(BASE_URL, `/site/${siteId}/trainer/${trainerId}/sessions/statistics`);
    } else {
      url = createUrl(BASE_URL, `/site/${siteId}/sessions/statistics`);
    }
  } else {
    url = createUrl(BASE_URL, `/sessions/statistics`);
  }
  return [
    url,
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth
  ];
}

export default async function request([url, options, data, auth]) {
  try {
    let token = null;
    if (auth) {
      token = await auth.getAccessToken();
    }
    for (const attribute in data) {
      if (data[attribute] === '' || data[attribute] === null) {
        delete data[attribute];
      }
    }
    return (
      await axios.request({
        url: url,
        method: options.method || 'get',
        ...{
          headers: options.headers || null,
        },
        ...{
          headers: token && createAuthHeader(token),
        },
        params: options.params || null,
        data: data,
        responseType: options.responseType || 'json',
      })
    ).data;
  } catch (err) {
    if (err.response) {
      throw {
        error: err.response.data,
        statusCode: err.response.status,
      };
    } else if (err.request) {
      throw {
        error: 'The request was made but no response was received.',
        statusCode: 408,
      };
    } else {
      throw {
        error: err.message,
        statusCode: 0,
      };
    }
  }
}
