import Stack from '@mui/material/Stack';
import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/SearchOutlined';

export default function sessionStatisticsFilter({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleFilter,
}) {
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
      <FormControl fullWidth margin="normal">
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
        />
      </FormControl>
      <IconButton
        size="large"
        color="primary"
        onClick={handleFilter}
      >
        <SearchIcon />
      </IconButton>
    </Stack>
  );
}
